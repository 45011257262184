@import '../../../../src/stylesheets/mixins'
@import '../../../../src/stylesheets/variables'


.types-filter
  &__wrapper
    display: flex
    flex-flow: wrap
    margin-bottom: 8px
    p
      @include fontSLH(14px, 20px)
      margin-bottom: 0

  &__checkbox-wrapper
    @include displayFlex(flex-start)
    margin-bottom: 16px
    padding-right: 2px
    cursor: pointer
    position: relative
    min-width: 50%
    @media (min-width: $tablet)
      min-width: 25%
      padding-right: 16px
    @media (min-width: $desktop-md)
      margin-right: 24px
      padding-right: 0
      min-width: unset
    &:before
      @include square(16px)
      content: ""
      border: 1px solid $borders
      border-radius: 2px
      position: relative
      display: inline-block
      margin-right: 8px
      background: $white
      top: 2px
    &:hover
      &:before
        border: 1px solid $green
    &--selected
      &:before
        border: 0 !important
        background: $green
    svg
      @include positionAbsolute(2px)
      fill: $white

  &__sales-wrapper
    margin-bottom: 0
    @media (min-width: $tablet)
      display: flex
    .no-padding
      padding: 0 !important
    p
      margin-bottom: 0

  &__title
    font-weight: 900
    margin-bottom: 8px
    @media (min-width: $tablet)
      margin-bottom: 0
    p
      @include fontSLH(14px, 21px)
      @media (min-width: $desktop-md)
        @include fontSLH(16px, 24px)

  &__mobile-columns
    display: flex
    flex-wrap: wrap
    column-count: 2
