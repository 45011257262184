@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'

.navigation-filter
  position: relative
  margin-top: 24px
  border: 1px solid $bg-badge-rented-out
  border-radius: 4px 4px 0 0
  border-bottom: none

  &__kinds-wrapper
    padding: 24px 15px 8px
    background-color: $border-light-grey
    display: flex
    flex-wrap: wrap
    @media (min-width: $tablet)
      padding: 24px 24px 8px
    @media (min-width: $desktop-md)
      padding: 24px 32px 8px

  &__kind-label
    @include displayFlex(flex-start)
    @include fontSLH(14px, 20px)
    flex-wrap: nowrap
    cursor: pointer
    margin-bottom: 16px
    position: relative
    min-width: 50%
    @media (min-width: $tablet)
      margin-right: 16px
      min-width: unset
    &:hover
      .navigation-filter__checkbox-icon
        &:before
          border: 1px solid $green

  &__kind-checkbox
    @include square(10px)
    position: relative
    display: inline-block
    padding-left: 1px !important
    margin-right: 14px
    top: 4px
    left: 3px
    &:checked
      & ~ .navigation-filter__checkbox-icon
        &:before
          @include displayFlex(center, center)
          content: ''
          background: $green
          border-color: $green

  &__checkbox-icon
    @include positionAbsolute(1px, $left: 0)
    &:before
      @include beforeEmptySquare(16px, $white, 0, $left: 0)
      border: 1px solid $inactive
      border-radius: 2px
    svg
      top: -3px
      position: relative
      fill: $white

  &__scroll-target
    position: absolute
