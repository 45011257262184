// MOBILE-FIRST
@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'


.favourite-icon

  &__with-label
    @include displayFlex(center)
    flex-wrap: nowrap
    cursor: pointer

  &__icon-wrapper
    svg
      color: $default
      &.active
        fill: $red

  &__label
    @include font($default, 14px, 16px)
    margin-left: 8px
    display: none
    @media (min-width: $tablet)
      display: block

  &__wrapper
    &.bounce
      .favourite-icon__icon:before,
      svg
        animation: bounce 1s .1s
        transform: scale(0.85)
    &--with-label svg
      color: $default
      margin-right: 8px
      &.active
        fill: $red

  &__click-area
    @include closeBtnRight(0, 0)
    padding: 16px 16px 16px 4px

  &__icon
    @include square(22px)
    @include displayFlex(center, center)
    position: relative
    border-radius: 4px
    background: rgba(255,255,255,0.8)
    svg
      fill: #BABABA
      pointer-events: none
    &.favourite svg
      fill: #EA4335

  &__tooltip
    @include font(12px, false, 20px, 100)
    padding: 0 11px
    border-radius: 5px
    background: $black
    text-align: center

@keyframes bounce
  0%
    transform: scale(1.1)
    opacity: 1
  50%
    transform: scale(1.3)
    opacity: .7
  60%
    transform: scale(0.6)
    opacity: 1
  80%
    transform: scale(0.95)
  90%
    transform: scale(0.85)
  100%
    transform: scale(1)
