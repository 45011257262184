@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'

.news-section
  margin-top: 64px
  margin-bottom: 64px
  @media (max-width: $screen-xs-max)
    h2
      line-height: 32px

  &__articles-wrapper
    @media (max-width: $screen-xs-max)
      height: 476px
      overflow: hidden

  &__headline
    font-weight: bold
    @include fontSLH(21px, 32px)
    margin-bottom: 24px
    @media (min-width: $desktop-md)
      @include fontSLH(24px, 32px)
      margin-bottom: 32px

  &__article-teaser
    height: 81px
    display: flex
    margin-bottom: 24px
    padding-bottom: 4.26px
    @media (min-width: $tablet)
      height: 156px

  &__article-img
    @include boxSize(86px, 81px)
    min-width: 86px
    border-radius: 5px
    background-repeat: no-repeat
    background-size: cover
    @media (min-width: $tablet)
      width: 167px
      height: 156px
      min-width: 167px

  &__article-info
    margin-left: 16px
    overflow: hidden
    @media (min-width: $tablet)
      display: flex
      flex-direction: column
      justify-content: center

  &__publish-date
    @include font(14px, #7F7F7F, 24px)
    margin-bottom: 8px

  &__article-link
    @include font(16px, $default, 24px, 900)
    position: relative
    overflow: hidden
    display: -webkit-box
    -webkit-line-clamp: 2
    -webkit-box-orient: vertical
    @media (min-width: $tablet)
      -webkit-line-clamp: 5
    @media (min-width: $desktop-md)
      -webkit-line-clamp: 2
    &:hover
      color: $green-hover

  &__see-all-link-wrapper
    display: flex
    justify-content: flex-end
    margin-top: -8px
    @media (min-width: $desktop-md)
      margin-top: 0

  &__see-all-link
    @include font(16px, $green, 24px)
    text-decoration: underline
    &:hover
      color: $green-hover

  &__widget-wrapper.success
    display: flex
    align-items: center


