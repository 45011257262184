// MOBILE_FIRST
@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'

.benefits
  margin-top: 64px
  margin-bottom: 64px

  &__title
    @include fontSLH(21px, 32px)
    margin: 0 0 32px 0
    @media (min-width: $tablet)
      margin-bottom: 24px
    @media (min-width: $desktop-md)
      margin-bottom: 32px
      font-size: 24px

  &__item
    @include displayFlex(center, center)
    border-radius: 4px
    background: $border-light-grey
    margin-bottom: 16px
    padding: 16px
    min-height: 116px
    @media (min-width: $desktop-md)
      min-height: 130px

  &__icon-wrapper
    @include font(30px, $green, 120%, 900)
    margin-right: 16px
    @media (min-width: $desktop-md)
      font-size: 45px
      margin-right: 24px
    svg
      fill: $green
      @media (min-width: $desktop-md)
        @include square(54px)
        min-width: 54px

  &__description
    @include font(16px, $default, 26px)
    @media (min-width: $tablet)
      @include fontSLH(16px, 26px)
    @media (min-width: $desktop-md)
      max-width: 206px
