// MOBILE-FIRST
@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'


.embla
  margin: auto
  --slide-height: 100%
  --slide-spacing: 0
  --slide-size: 100%
  --slide-spacing-sm: 1.6rem
  --slide-size-sm: 50%
  --slide-spacing-lg: 16px
  --slide-size-lg: calc(100% / 3)
  --slide-spacing-xl: 16px
  --slide-size-xl: calc(100% / 4)
  --slide-size-xxl: calc(100% / 5)
  &.multi-slides
    --slide-spacing: 16px

.embla__viewport
  overflow: hidden

.embla__container
  display: flex
  touch-action: pan-y pinch-zoom
  margin-left: calc(var(--slide-spacing) * -1)
  scroll-behavior: smooth

.embla__slide
  transform: translate3d(0, 0, 0)
  flex: 0 0 var(--slide-size)
  min-width: 0
  padding-left: var(--slide-spacing)

.embla__slide__number
  box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast)
  border-radius: 1.8rem
  font-size: 4rem
  font-weight: 600
  display: flex
  align-items: center
  justify-content: center
  height: var(--slide-height)
  user-select: none

.embla__controls
  justify-content: space-between

.embla__buttons
  display: flex
  justify-content: space-between
  align-items: center

.embla__button
  @include displayFlex(center, center)
  cursor: pointer
  z-index: 1

.embla__button:disabled
  color: var(--detail-high-contrast)

.embla__dots-wrapper
  @include displayFlex(center, center)
  width: 100%

.embla__dots
  display: flex
  align-items: center
  width: auto
  overflow: scroll
  -ms-overflow-style: none
  scrollbar-width: none
  touch-action: none
  scroll-behavior: smooth
  max-width: 70px
  &::-webkit-scrollbar
    display: none

.embla__dot
  -webkit-tap-highlight-color: rgba(var(--text-high-contrast-rgb-value), 0.5)
  -webkit-appearance: none
  appearance: none
  background-color: transparent
  touch-action: manipulation
  display: inline-flex
  text-decoration: none
  cursor: pointer
  border: 0
  padding: 0
  margin: 0 3px
  width: 8px
  height: 8px
  display: flex
  align-items: center
  justify-content: center
  border-radius: 50%
  gap: 8px
  background: rgba(255, 255, 255, 0.60)
  pointer-events: none
  &--selected
    background: white

.embla__dot:after
  box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast)
  width: 1.4rem
  height: 1.4rem
  border-radius: 50%
  display: flex
  align-items: center
  content: ''

.embla__dot--selected:after
  box-shadow: inset 0 0 0 0.2rem var(--text-body)

.embla.multi-slides
  .embla__container
    margin-left: 0
    @media (min-width: 768px)
      margin-left: calc(var(--slide-spacing) * -1)
  .embla__slide
    flex: 0 0 282px
    padding-left: 16px
    @media (min-width: 768px)
      flex: 0 0 var(--slide-size-sm)
    @media (min-width: 992px)
      flex: 0 0 var(--slide-size-lg)
    @media (min-width: 1200px)
      flex: 0 0 var(--slide-size-xl)
  .embla__dots
    margin-top: 16px
    max-width: 100%
  .embla__controls
    position: unset
    padding-top: 16px
  .embla__dot
    background: #BDBDBD
    width: 6px
    height: 6px
    &--selected
      background: $default
      width: 8px
      height: 8px
  .embla__buttons
    position: absolute
    top: calc(50% - 15px)
    width: calc(100% + 76px)
    left: -38px
    display: none
    @media (min-width: 992px)
      display: flex
  &.full-width .embla__slide
    @media (min-width: 1400px)
      flex: 0 0 var(--slide-size-xxl)
