@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'

.ep-bottom-seo
  &__teaser-area
    width: 100%
    h2
      margin-top: 0
      font-size: 21px
      margin-bottom: 16px
      @media (min-width: $tablet)
        font-size: 21px
        margin-bottom: 24px
      @media (min-width: $desktop-md)
        font-size: 24px
        margin-bottom: 32px

  &__body
    width: 100%
    margin-bottom: 40px
    img
      max-width: 100%
      margin-bottom: 24px
      border-radius: 4px

  &__columns
    -moz-column-count: 3
    -moz-column-gap: 16px
    -ms-column-count: 3
    -ms-column-gap: 16px
    -webkit-column-count: 3
    -webkit-column-gap: 16px
    column-gap: 16px
    margin-bottom: 10px
    column-count: 1
    @media (min-width: $tablet)
      column-count: 2
      margin-bottom: 0
    @media (min-width: $desktop-md)
      column-count: 3

    h3
      @include fontSLH(16px, 26px)
      font-weight: bold
      margin-bottom: 16px
      margin-top: 32px
      break-after: avoid
      break-inside: avoid
      @media (min-width: $desktop-md)
        font-size: 18px

    h4
      @include fontSLH(16px, 24px)
      margin: 10px 0

    p
      @include fontSLH(16px, 24px)

  &__read-more
    color: $green
    margin-top: 16px
    cursor: pointer
    display: block
    text-decoration: underline
    position: relative
    svg
      @include positionAbsolute(1px)
      margin-left: 10px
      fill: $green
    @media (min-width: $tablet)
      display: none

  &__complete
    display: none
    p
      margin-bottom: 0
      display: initial
    @media (min-width: $tablet)
      display: block

    &.inline
      display: inline !important
