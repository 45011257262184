@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'


.list-view-banner
  margin-bottom: 64px

  &__background-wrapper.lazyloaded
    background-repeat: no-repeat
    background-position: center
    background-size: cover
    background-image: url('../../../src/images/entry_pages/list-banner-background.png')
    padding: 48px 16px
    @media (min-width: $tablet)
      border-radius: 4px
      padding: 48px
    @media (min-width: $desktop-md)
      padding: 64px

  &__content-wrapper
    @include displayFlex($justifyContent: space-between)
    border-radius: 4px
    background: #fff
    padding: 32px 24px
    max-width: 1160px
    margin: auto
    @media (min-width: $tablet)
      padding: 48px
    @media (min-width: $desktop-md)
      padding: 64px

  &__content
    @media screen and (min-width: $tablet) and (max-width: 991px)
      max-width: 280px

  &__title
    @include fontSLH(18px, 29px)
    margin-top: 0
    font-weight: bold
    @media (min-width: $tablet)
      @include fontSLH(21px, 32px)
    @media (min-width: $desktop-md)
      font-size: 24px

  &__description
    @include fontSLH(16px, 24px)
    margin-top: 16px
    margin-bottom: 24px
    @media (min-width: $desktop-md)
      margin-top: 24px
      margin-bottom: 32px

  .btn-orange
    @include boxSize(100%, 48px)
    position: relative
    padding-left: 22px
    justify-content: flex-start
    text-transform: inherit
    @media (min-width: $tablet)
      width: 280px
    &:hover
      background-color: $orange-hover
    svg
      @include positionAbsolute(9px, 33px)
      margin-right: 0

  &__illustration-wrapper
    @media (max-width: 767px)
      display: none
