// MOBILE_FIRST
@import '../../src/stylesheets/variables'
@import '../../src/stylesheets/mixins'


.new-badge
  @include displayFlex(center, center)
  border-radius: 4px
  //position: absolute
  z-index: 20
  color: $white
  font-weight: bold
  text-transform: capitalize
  background: $bg-badge-new

  &--list-view span,
  &--grid-view span
    @include font(14px, $white, 21px, bold)
    padding: 6px 8px

  &--list-view,
  &--grid-view
    margin-bottom: 6px
    //top: 3px
    //display: flex
    //font-size: 7px
    //@media (min-width: $tablet)
    //  top: 10px
    //  display: block
    //  font-size: .7em
    //  padding: 5px
    //span
    //  @include font(14px, $white, 21px, bold)
    //  padding: 6px 8px
      //@media (max-width: $screen-xs-max)
      //  top: 5px
      //  font-size: 8px
      //  line-height: 8px

  //&--grid-view
  //  top: 51px

  &--detail-view
    position: relative
    padding: 4px 12px
    align-items: flex-start
    margin-right: 16px
    border-radius: 4px
    margin-bottom: 8px
    @media (min-width: $desktop-md)
      padding: 8px 12px
    span
      @include fontSLH(14px, 24px)
      font-weight: bold
      font-family: 'MuseoSans', sans-serif
      @media (min-width: $desktop-md)
        font-size: 16px

.special-offer-badge
  //@include positionAbsolute($left: 0)
  background-color: $bg-badge-special-offer
  border-radius: 4px
  z-index: 11
  color: $white
  font-weight: bold
  max-width: 100%
  display: flex
  flex-wrap: wrap

  &--list-view span,
  &--grid-view span
    @include font(14px, $white, 21px, bold)
    padding: 6px 8px

  &--list-view,
  &--grid-view
    margin-bottom: 6px

  //&--list-view

    //top: 28px
    //span
    //  @include font(14px, $white, 21px, bold)
    //  padding: 6px 8px
      //@media (max-width: $screen-xs-max)
      //  top: 5px
      //  font-size: 8px
      //  line-height: 8px
    //@media (min-width: $tablet)
    //  @include fontSLH(12px, 17px)
      //top: 47px

  //&--grid-view
  //  @include fontSLH(12px, 17px)
  //  padding: 6px 8px
    //top: 84px

  //&--list-view,
  //&--grid-view
  //  padding: 5px

  &--detail-view
    @include fontSLH(14px, 24px)
    border-radius: 4px
    position: relative
    padding: 4px 12px
    margin-bottom: 8px
    @media (min-width: $desktop-md)
      font-size: 16px
      padding: 8px 12px


.stripe-badge
  //position: absolute
  text-align: center
  font-weight: bold
  width: 100%
  z-index: 11

  &.crawled
    color: $white
    height: 62px
    left: 0
    .top-wrapper,
    .bottom-wrapper
      height: 31px
      line-height: 31px
    .top-wrapper
      background-color: $bg-badge-parsed
    .bottom-wrapper
      background-color: $default
      &__content
        font-size: 14px
        margin: 0
        line-height: 31px
        font-family: MuseoSans, Helvetica, Arial, sans-serif
        font-weight: 300
        span
          text-decoration: underline
          color: $green

  &--list-view
    &.anonymous, &.crawled
      @include font(14px, $white, 31px)
      //@include font(8px, $white, 10px)
      //bottom: 0
      border-radius: 2px 2px 0 0
      //@media (min-width: $tablet)
      //  @include fontSLH(12px, 31px)
      //@media (max-width: $screen-xs-max)
      //  height: auto
    &.rented-out
      @include fontSLH(14px, 31px)
      height: 31px
      background-color: $bg-badge-rented-out
      //top: auto
      //bottom: 10px

      //@media (min-width: $tablet)
      //  @include fontSLH(14px, 31px)
        //height: 31px

    &.anonymous
      height: 31px
      background-color: $bg-badge-anonymous
      //@media (max-width: $screen-xs-max)
      //  height: 21px
      //  line-height: 21px
    &.crawled
      position: absolute
      bottom: 0
      left: 0
      width: calc(100% - 8px)
      height: 31px
      .top-wrapper
        background-color: $bg-badge-parsed
      .bottom-wrapper
        display: none
      @media (max-width: $screen-xs-max)
        position: absolute
        bottom: 0
        .bottom-wrapper
          display: none
        .top-wrapper
          line-height: 10px
          height: auto
          padding: 4px 0


  &--grid-view
    &.anonymous, &.crawled
      line-height: 31px
      color: $white
      border-radius: 2px 2px 0 0
    &.rented-out
      @include font(1em, $default, 40px)
      height: 40px
      bottom: 52px
      top: auto
      z-index: 6
      background-color: $bg-badge-rented-out
    &.anonymous
      height: 31px
      font-size: 12px
      background-color: $bg-badge-anonymous
      bottom: 52px
    &.crawled
      height: 31px
      position: absolute
      bottom: 0
      .top-wrapper
        font-size: 14px
        background-color: $bg-badge-parsed
      .bottom-wrapper
        display: none

  &--detail-view
    height: 48px
    line-height: 48px
    font-size: 16px
    border-radius: 2px 2px 0 0
    &.rented-out
      color: $default
      background-color: $bg-badge-rented-out
      position: relative
      bottom: 0
      height: 72px
      line-height: 72px
      font-size: 21px
      border-radius: 0 0 4px 4px
      @media (max-width: 767px)
        @include displayFlex(center, center)
        line-height: 24px
      @media (min-width: $desktop)
        font-size: 24px
        border-radius: 2px 2px 4px 4px

    &.anonymous
      color: $white
      background-color: $bg-badge-anonymous
      bottom: 0
      position: absolute
    &.crawled
      position: absolute
      height: 96px
      bottom: 0
      .top-wrapper,
      .bottom-wrapper
        height: 48px
        line-height: 48px
        &__content
          line-height: 48px
          font-size: 16px
      .top-wrapper
        background-color: $bg-badge-parsed
      @media (max-width: $screen-sm)
        height: auto
        .bottom-wrapper
          height: auto
          padding: 10px 20px
          &__content
            line-height: 24px
            font-size: 14px
      @media (max-width: $screen-xs-max)
        .bottom-wrapper
          padding: 16px 5.375%


.description-badge
  @include displayFlex(center)
  p
    color: $default
    margin: 0

  &--detail-view
    margin-right: 16px
    img
      margin-right: 9px
    p
      @include fontSLH(14px, 24px)
      font-family: MuseoSans, sans-serif
      white-space: nowrap
      @media (min-width: $desktop-md)
        font-size: 16px

