@import '../../../../src/stylesheets/variables'
@import '../../../../src/stylesheets/mixins'


.basic-search
  &__input-section
    @include displayFlex(center)
    margin: 22px 0 12px 0
    @media (min-width: $tablet)
      margin: 8px 0 12px 0
    @media (min-width: $desktop)
      margin: 16px 0 16px 0

  &__input-wrapper
    @include displayFlex(center)
    @include boxSize(100%, 48px)
    position: relative
    border: 1px solid $inactive
    border-radius: 5px
    padding: 1px
    &.focus
      border: 1px solid $green
    &.error
      border: 1px solid $light-red
    svg
      @include positionAbsolute($left: 12px)
      fill: $dark-borders
      @media (min-width: $desktop)
        left: 16px

  &__input
    height: 44px
    font-size: 16px
    padding: 10px 16px 10px 43px
    outline: none
    border: none
    flex-grow: 1
    width: 100%
    @media (min-width: $tablet)
      padding: 10px 16px 10px 58px
    @media (max-width: 350px)
      width: 150px
    &::-webkit-input-placeholder
      font-size: 16px
      color: $dark-borders
      font-family: MuseoSans, Helvetica, Arial, sans-serif
    &::-ms-clear
      display: none

  &__clear-btn
    cursor: pointer
    color: $link-list-view-hover
    opacity: 0.7
    &:hover
      opacity: 1
    svg
      fill: $link-list-view-hover
      position: relative
      left: 0

  &__voice-record
    @include square(42px)
    text-align: center
    border-radius: 50%
    cursor: pointer
    display: block
    margin-right: 8px
    min-width: 42px
    svg
      fill: #313334
      margin-top: 6px
      position: relative
      left: 0
    &:hover
      box-shadow: 0 0 7px 0 #E0E0E0
      svg
        fill: $green
    &.active
      box-shadow: 0 0 7px 0 #E0E0E0
      svg
        fill: $light-red

  &__error-wrapper
    padding-bottom: 16px
    @media (min-width: $tablet)
      padding-top: 4px
      padding-bottom: 0

  &__error-text
    @include font(12px, $light-red, 20px, 300)

  &__submit-button-wrapper
    @media (min-width: $tablet)
      @include displayFlex(flex-end, space-between)

  &__submit-button
    @include font(16px, $white, 48px, 900)
    height: 48px
    background-color: $orange
    border-radius: 5px
    text-align: center
    cursor: pointer
    &:hover
      background-color: $dark-orange
    @media (min-width: $tablet)
      min-width: 198px
      padding: 0 24px

  &__submit-button-tooltip
    display: none
    @media (min-width: $tablet)
      @include font(14px, $dark-borders, 30px)
      display: block
      max-width: 342px
      margin-right: 24px

  &__dropdown-body
    @include positionAbsolute(55px, $left: 0)
    width: 100%
    box-shadow: 0 6px 15px 0 rgba(0,4,10,0.15)
    overflow: auto
    z-index: 10
    padding: 16px
    background-color: $white
    border-radius: 4px
    @media (min-width: $desktop-md)
      width: 80%
      top: 63px
      padding: 24px

  &__dropdown-body-section
    border-bottom: 1px solid $borders
    margin-bottom: 8px
    padding-bottom: 8px
    &:last-child
      border: none
      margin: 0
      padding: 0
    @media (min-width: $tablet)
      margin-bottom: 16px
      padding-bottom: 16px

  &__dropdown-body-section-label
    @include font(12px, $gull-grey-text, 24px, 900)
    background-color: $white
    text-transform: uppercase
    padding: 0 0 8px
    @media (min-width: $tablet)
      font-size: 14px

  &__dropdown-body-option
    @include displayFlex($justifyContent: space-between)
    color: $black
    font-size: 14px
    padding: 8px
    border-radius: 4px
    &:hover, &.selected
      background: $border-light-grey
      text-decoration: none
    @media (min-width: $desktop-md)
      font-size: 16px

  &__dropdown-body-option-text
    padding-right: 5px

  &__dropdown-body-option-teaser
    text-align: right
