// MOBILE_FIRST
@import '../../../src/stylesheets/mixins'
@import '../../../src/stylesheets/variables'

.location-template
  text-decoration: none
  @include square(100%)
  display: block
  position: relative
  &:hover
    text-decoration: none
    .location-template__info-block-wrapper,
    .location-template__image-wrapper
      box-shadow: 0 1px 4px 0 rgba(49,60,63,0.25)
      -webkit-box-shadow: 0 1px 4px 0 rgba(49,60,63,0.25)

  &__image-wrapper
    position: relative

  &__info-tooltip
    line-height: 22px
    svg
      fill: #7F7F7F
      margin-right: 0 !important
      position: relative
      bottom: 1px
    &:hover svg
      fill: $green

  .location-template-tooltip
    padding: 4px 8px
    font-size: 12px
    line-height: 15px
    font-weight: 300
    max-width: 130px
    text-align: left

  p.location-template__date
    @include font(14px, $default, 24px)
    font-family: MuseoSans, sans-serif
    margin-bottom: 8px

  &__image-wrapper
    @include fullWidthSize(164px)
    background-size: cover
    background-position: center
    border-top-left-radius: 4px
    border-top-right-radius: 4px

  &__info-block-wrapper
    padding: 8px 16px 16px 16px
    border-bottom-left-radius: 4px
    border-bottom-right-radius: 4px
    border: 1px solid $bg-badge-rented-out
    border-top: none
    height: calc(100% - 164px)
    background: $white

  &__location-address
    margin-bottom: 16px
    p
      @include font(16px, $default, 26px, bold)
      @include truncate()
      margin: 0

  &__location-kinds
    display: flex
    span
      @include font(14px, $default, 24px)
      font-weight: 300
      &:before
        content: '·'
        margin: 0 8px
      &:first-child:before
        display: none

  &__info-wrapper
    margin-top: 8px

  &__info-row
    @include displayFlex($justifyContent: space-between)
    display: flex
    width: 100%
    p
      @include font(14px, $default, 24px)
      font-weight: 300
      margin: 0
      max-width: 50%
      svg
        margin-right: 10px

  &__info-value
    -ms-text-align-last: right
    text-align: right

  &__info-label
    display: flex
    align-items: flex-start
    svg
      min-width: 22px

  &__badge-wrapper
    .stripe-badge--list-view.rented-out
      @include positionAbsolute(133px, 0)
      color: $default
    &.with-indentation .stripe-badge--list-view.rented-out
      top: 165px


