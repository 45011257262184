// MOBILE-FIRST
@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'

.latest-locations
  padding-top: 64px

  &__title
    @include font(21px, $default, 32px, bold)
    padding-bottom: 16px
    margin: 0
    @media (min-width: $desktop-md)
      font-size: 24px
      padding-bottom: 24px

  &__slider-wrapper.container
    @media (max-width: $tablet)
      padding: 0 !important
      width: 100vw !important
      margin: 0
      overflow: visible

  .location-template
    box-shadow: none
