// MOBILE-FIRST
@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'

.popular-areas
  padding-top: 64px
  margin-bottom: 48px
  @media (min-width: $desktop-md)
    padding-top: 56px

  &__title
    @include font(21px, $default, 32px, bold)
    padding-bottom: 16px
    margin: 0
    @media (min-width: $desktop-md)
      font-size: 24px
      padding-bottom: 24px

  &__list
    display: flex
    flex-wrap: wrap
    overflow: hidden
    gap: 16px

  &__link
    @include font(14px, $default, 20px)
    display: block
    white-space: nowrap
    padding: 6px 12px
    border: 1px solid $default
    border-radius: 16px
    &:last-child
      margin-right: 0
    &:hover
      text-decoration: underline
      color: $green-hover
      border-color: $green-hover

  &__collapse-switcher
    @include fontSLH(16px, 24px)
    display: inline-flex
    align-items: center
    color: $green
    cursor: pointer
    margin-top: 16px
    svg
      fill: $green
      margin-left: 8px
    &:hover
      color: $green-hover
      svg
        fill: $green-hover

  &.collapsible
    &.close .popular-areas__list
      min-height: 0
      height: 84px
      animation: .25s ease 0s closeMobile
      @media (min-width: $desktop-md)
        height: 34px
        animation: .25s ease 0s close992px
    &.open
      .popular-areas__list
        height: auto
        min-height: 34px
        animation: .25s ease 0s openMobile
        @media (min-width: $desktop-md)
          min-height: 34px
          animation: .25s ease 0s open992px

@keyframes openMobile
  from
    min-height: 0
    height: 84px
  to
    height: auto
    min-height: 84px

@keyframes closeMobile
  from
    height: auto
    min-height: 84px
  to
    min-height: 0
    height: 84px

@keyframes open992px
  from
    min-height: 0
    height: 34px
  to
    height: auto
    min-height: 34px

@keyframes close992px
  from
    height: auto
    min-height: 34px
  to
    min-height: 0
    height: 34px
