@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'

.provider-logos

  &__wrapper
    border-top: 1px solid $bg-badge-rented-out
    border-bottom: 1px solid $bg-badge-rented-out
    padding: 32px 0
    @media (min-width: $desktop-md)
      @include displayFlex(center, space-between)

  &__headline
    @include fontSLH(18px, 26px)
    margin-bottom: 16px
    font-weight: 900
    @media (min-width: $desktop-md)
      margin-bottom: 0

  &__images-list
    @include displayFlex($justifyContent: center)
    position: relative
    flex-wrap: wrap
    @media (min-width: $tablet)
      justify-content: space-between
    @media (min-width: $desktop-md)
      width: 100%

  &__image-link
    display: inline-block
    text-align: center
    margin: 12px 20px
    cursor: pointer
    @media (min-width: $tablet)
      margin: 12px 0
    @media (min-width: $desktop-md)
      margin: 10px 0 10px 24px

    img[data-sizes="auto"]
      width: auto
      height: 24px
      filter: grayscale(1)
      display: inline-block
      @media (min-width: $desktop-md)
        height: 38px
      &:hover
        filter: none
