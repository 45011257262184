@import '../../src/stylesheets/variables'
@import '../../src/stylesheets/mixins'


.buttons
  &__icon-wrapper
    padding: 7.5px
    &> svg
      fill: $white

  &__wrapper
    @include square(60px)
    @include displayFlex(center, center)
    border-radius: 50%
    border: 1px solid $green
    background: #FFF
    position: fixed
    right: 24px
    z-index: 12
    bottom: 96px
    cursor: pointer
    &.hidden
      display: none
    svg
      fill: $green
    &:hover
      border-color: $green-hover
      svg
        fill: $green-hover

  &__cta-wrapper
    padding: 15px 15px 15px
    max-width: 100%
    &> button
      white-space: normal
      width: auto
    @media only screen and (min-width: $screen-s-xs)
      display: none

  &__button
    cursor: pointer
    padding: 10px 24px 9px
    display: block
    text-align: center
    font-size: 14px
    width: 100%
    white-space: nowrap
    border: none
    &:hover
      background-color: $dark-orange

