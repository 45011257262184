@import '../../src/stylesheets/variables'
@import '../../src/stylesheets/mixins'


.entry-page
  &__content
    color: $default

  .FAQSection__title
    text-align: center

  .search-agent-section__wrapper
    @media (min-width: $desktop-md)
      img
        width: 153px

    @media (min-width: $desktop)
      padding: 48px
      .search-agent-section__text-wrapper
        margin-left: 72px

.container.max-width,
.container.top-menu__container,
.breadcrumbs .container,
.trusted-logos .container,
.popular-areas .container,
.footer-container.container
  @media (min-width: $desktop-md)
    width: calc(100vw - 64px) !important
  @media (min-width: $desktop)
    width: calc(100vw - 96px) !important

