// MOBILE_FIRST
@import "../../../src/stylesheets/variables"
@import '../../../src/stylesheets/mixins'

.base-slider
  position: relative
  @media (min-width: $tablet)
    margin: 0 24px
  @media (min-width: $desktop-md)
    margin: 0
