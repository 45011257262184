// MOBILE-FIRST
@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'

.search-agent-section
  color: $default

  &__wrapper
    @include displayFlex(flex-start, space-between, column)
    position: relative
    padding: 16px 16px 16px
    border-radius: 4px
    background-color: $border-light-grey
    @media (min-width: $tablet)
      flex-direction: row
      align-items: center
    @media (min-width: $tablet)
      padding: 16px
    @media (min-width: $desktop-md)
      padding: 32px

  &__image-text-wrapper
    @include displayFlex(center)

  &__headline
    max-width: 100%
    margin-bottom: 16px
    text-align: initial
    @include font(21px, #000, 32px, 900)
    @media (min-width: $desktop-md)
      @include fontSLH(24px, 32px)
      max-width: 95%

  &__image-wrapper
    display: none
    @media (min-width: $desktop)
      display: block

  &__text-wrapper
    margin-left: 0
    @media (min-width: $desktop)
      margin-left: 24px
    @media (max-width: $tablet)
      margin-bottom: 16px

  &__text
    @include fontSLH(16px, 24px)
    max-width: 100%
    align-self: flex-start
    @media (min-width: $tablet)
      max-width: 95%
    p
      @include fontSLH(16px, 24px)
      margin: 0
      position: relative
      @media (min-width: $tablet)
        font-size: 16px

  &__button
    @include displayFlex(center, center)
    background-color: $green
    border-radius: 4px
    padding: 8px 10px
    width: 100%
    max-width: 100%
    text-decoration: none
    @media (min-width: $desktop-md)
      padding-top: 12px
      padding-bottom: 12px
    &:hover
      text-decoration: none
      background-color: $green-hover
    span
      @include font(16px, $white, 24px, bold)
      @media (max-width: $mobile)
        text-align: center

  &__buttons-block
    width: 100%
    position: relative
    @media (min-width: $tablet)
      max-width: 321px
