@import '../../src/stylesheets/variables'
@import '../../src/stylesheets/mixins'

.loader
  @include square(20px)
  position: absolute
  border-radius: 50%
  background: transparent
  border: 2px solid $white
  border-bottom-color: rgba(255, 255, 255, 0.5)
  border-left-color: rgba(255, 255, 255, 0.5)
  animation: rotating 2s linear infinite

@keyframes rotating
  from
    transform: rotate(0deg)
  to
    transform: rotate(360deg)
