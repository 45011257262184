// MOBILE_FIRST
@import "../../../src/stylesheets/variables"
@import '../../../src/stylesheets/mixins'


.ai-search
  height: 316px
  display: flex
  flex-direction: column
  @media (min-width: $tablet)
    height: 230px
  @media (min-width: $desktop)
    height: 206px

  &__input-wrapper
    @include displayFlex(flex-start)
    position: relative
    top: -8px
    border-radius: 5px
    border: 1px solid #CBCECF
    padding: 12px
    height: 100%
    @media (min-width: $tablet)
      top: 0
      margin-bottom: 12px
    @media (min-width: $desktop)
      margin-bottom: 16px
    &.disabled
      cursor: no-drop
      background: #f6f6f6
    &.focused
      border-color: $green

  &__search-icon
    fill: #5A6365
    margin-right: 16px

  &__voice-record
    @include positionAbsolute($right: 12px, $bottom: 12px)
    @include square(42px)
    text-align: center
    border-radius: 50%
    cursor: pointer
    display: block
    svg
      fill: #313334
      margin-top: 6px
      position: relative
      left: 0
    &:hover
      box-shadow: 0 0 7px 0 #E0E0E0
      svg
        fill: $green
    &.active
      box-shadow: 0 0 7px 0 #E0E0E0
      svg
        fill: #FF5A5A

  textarea
    @include font(16px, $default, 30px)
    width: 100%
    padding-right: 12px
    height: calc(100% - 39px)
    border: none
    resize: none
    &::placeholder
      @include font(16px, #5A6365, 30px)
    &:focus-visible
      outline: none
    &:disabled
      cursor: no-drop

  &__bottom-wrapper
    @media (min-width: $tablet)
      @include displayFlex(center, space-between)

  &__bottom-tooltip
    @include font(14px, #5A6365, 18px)
    max-width: 342px
    margin-bottom: 8px
    @media (min-width: $tablet)
      margin-right: 24px

  &__submit-button
    @include displayFlex(center, center)
    height: 48px
    line-height: 48px
    font-weight: bold
    font-size: 16px
    color: #fff
    background-color: #FFA300
    border-radius: 5px
    text-align: center
    cursor: pointer
    position: relative
    &:hover
      background-color: $dark-orange
    @media (min-width: $tablet)
      min-width: 198px
      padding: 0 24px
    i
      vertical-align: middle
    &.disabled
      background-color: $dark-orange
      pointer-events: none
      cursor: no-drop
    .loader
      position: relative
      margin-left: 16px

  &.error
    .ai-search__input-wrapper
      border-color: $red
    .ai-search__bottom-tooltip
      color: $red
