@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'


.top-seo
  margin-bottom: 64px

  &__headline
    @include fontSLH(21px, 32px)
    margin-bottom: 24px
    @media (min-width: $desktop-md)
      font-size: 24px
      margin-bottom: 32px

  &__content
    @media (min-width: $tablet)
      column-fill: balance
      column-count: 2
      column-gap: 16px
    @media (min-width: $desktop-md)
      column-count: 3
    p
      @include font(16px, $default, 24px)
    h3
      margin-bottom: 16px
