@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'

.ep-location-navigator
  background-color: $white
  border: 1px solid $bg-badge-rented-out
  border-radius: 0 0 4px 4px
  @media (max-width: $screen-xs-max)
    border-top: none

  &__header
    @include displayFlex(flex-start)
    padding: 16px 16px 12px
    min-height: 24px
    &.not-active
      padding: 0
    @media (min-width: $tablet)
      min-height: 51px
      padding: 14px 24px 12px 20px

  &__breadcrumbs
    @include displayFlex(center)
    flex-wrap: wrap

  &__breadcrumbs-link
    @include displayFlex(center)
    color: #757575
    margin-bottom: 4px
    font-weight: 300

    span
      @include font(12px, $default, 20px)
      cursor: pointer
      display: block
      &:hover
        text-decoration: underline
        color: $green

    svg
      margin: 0 3px
      fill: $gull-grey-text

    &:last-child
      span
        pointer-events: none
        cursor: none
        color: $gull-grey-text
      svg
        display: none

  &__back-btn
    @include displayFlex(center)
    position: relative
    cursor: pointer
    margin-right: 16px
    span
      @include font(12px, $default, 20px, bold)
      font-family: MuseoSans, sans-serif
    svg
      margin-right: 5px

    &:hover
      span
        text-decoration: underline
        color: $green-hover
      svg
        fill: $green-hover

  &__wrapper
    @include displayFlex(stretch)
    position: relative

    &.cols-2
      .ep-location-navigator__locations-list
        column-count: 2
        @media (min-width: $desktop-md)
          column-count: 3

    &.cols-3 .ep-location-navigator__locations-list
      column-count: 3

    &.cols-2:before,
    &.cols-2:after,
    &.cols-3:before,
    &.cols-3:after
      display: none
      @media (min-width: $tablet)
        content: ''
        position: absolute
        height: calc(100% - 51px)
        bottom: 0
        border-right: 1px solid $borders
        display: block

    &.cols-2:after,
    &.cols-3:after
      right: 33.333%
      @media (min-width: $desktop-md)
        right: 25%

    &.cols-2:before,
    &.cols-3:before
      left: 33.333%
      @media (min-width: $desktop-md)
        left: 50%

    &.tablet.cols-3
      @media (min-width: $desktop-md)
        &:before
          left: 33.333%
        &:after
          right: 33.333%

    &.shorten:before,
    &.shorten:after
      height: calc(100% - 99px)
      bottom: 48px

  &__map-col
    display: none
    width: 33.333%
    min-width: 33.333%
    position: relative
    background-color: $border-light-grey
    border-right: 1px solid $borders
    img[data-sizes="auto"]
      width: 100%
      height: auto
      max-height: 350px
      padding: 50px 16px 9px
      @media (min-width: $desktop-md)
        padding-top: 37px
    @media (min-width: $tablet)
      display: block
    @media (min-width: $desktop-md)
      width: 25%
      min-width: 25%

  &__main-block-wrapper
    width: 100%
    display: flex
    flex-direction: column
    justify-content: space-between

  &__main-block
    width: 100%

  &__locations-list
    position: relative
    padding: 0 16px 8px
    display: flex
    flex-direction: column
    width: 100%

    @media (min-width: $tablet)
      display: block
      column-gap: 48px
      padding: 0 24px 32px

  &__location-link
    display: block
    text-decoration: none
    position: relative
    cursor: pointer
    padding-bottom: 16px
    -webkit-column-break-inside: avoid
    page-break-inside: avoid
    break-inside: avoid

    &:hover
      p
        color: $green-hover
        text-decoration: underline
      &:after
        content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='%23539533'%3E%3Cpath d='M9.29 15.88L13.17 12 9.29 8.12c-.39-.39-.39-1.02 0-1.41.39-.39 1.02-.39 1.41 0l4.59 4.59c.39.39.39 1.02 0 1.41L10.7 17.3c-.39.39-1.02.39-1.41 0-.38-.39-.39-1.03 0-1.42z'/%3E%3C/svg%3E")

    &:after
      @include positionAbsolute(-1px, -2px)
      content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='%23000000'%3E%3Cpath d='M9.29 15.88L13.17 12 9.29 8.12c-.39-.39-.39-1.02 0-1.41.39-.39 1.02-.39 1.41 0l4.59 4.59c.39.39.39 1.02 0 1.41L10.7 17.3c-.39.39-1.02.39-1.41 0-.38-.39-.39-1.03 0-1.42z'/%3E%3C/svg%3E")
    p
      @include font(14px, $default, 24px)
      max-width: calc(100% - 16px)
      margin: 0

  &__footer
    text-align: center
    border-top: 1px solid $bg-badge-rented-out
    padding: 12px 16px

  &__show-all-link
    @include font(16px, $green, 24px, bold)
    font-family: MuseoSans, sans-serif
    text-decoration: underline
    &:hover
      color: $green-hover

  &__no-premises
    text-align: center
    padding: 10px 0
    width: 100%
