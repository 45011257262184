@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'

.geo-navigation
  position: relative
  overflow: hidden
  padding-top: 64px

  &__scroll-target
    position: absolute

  &__title
    @include font(21px, $default, 32px)
    margin: 0 0 16px
    @media (min-width: $desktop-md)
      font-size: 24px

  &__welcome-content p
    @include fontSLH(16px,24px)
    margin: 0
