@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'


.top-section
  position: relative
  box-sizing: border-box
  width: 100%
  z-index: 2
  background: $border-light-grey
  padding-bottom: 46px
  @media (min-width: $tablet)
    background: transparent
    padding-bottom: 0

  &__grey-background
    @include boxSize(100%, 410px)
    background: $border-light-grey
    position: absolute
    display: none
    @media (min-width: $tablet)
      display: block
    &:after
      @include positionAbsolute(0, -50px, 0, 0)
      @include boxSize(60%, 100%)
      content: ""
      background: $border-light-grey
      display: block
      transform: skew(-22deg)
      z-index: 1
      @media (min-width: $desktop-md)
        width: 47%

  &__title-image
    display: none
    @media (min-width: $tablet)
      @include positionAbsolute(0, 0)
      @include boxSize(60%, 100%)
      @include displayFlex(center)
      display: block
      overflow: hidden
    img
      @include square(auto)
      object-position: center
      object-fit: cover
      max-width: 100%
      min-height: 100%
      @media (min-width: $desktop)
        float: right

  &__phone-block
    @include fontSLH(16px, 18px)
    @include positionAbsolute(22px, $left: 50%)
    display: none
    text-align: right
    position: absolute
    background: rgba(255, 255, 255, 0.80)
    backdrop-filter: blur(7.5px)
    height: 48px
    padding: 0 16px 0 48px
    @media (min-width: $desktop-md)
      @include displayFlex(center, center)

  &__title-wrapper
    @include displayFlex(center, space-between)
    position: relative
    padding: 32px 0 38px
    @media (min-width: $desktop-md)
      padding: 56px 0 38px
      width: 54%

  &__title-text
    width: 100%
    z-index: 2

  &__main-title
    @include font(24px, $default, 32px, 900)
    margin-bottom: 16px
    display: none
    @media (min-width: $tablet)
      display: block
      width: 66%
    @media (min-width: $desktop-md)
      @include fontSLH(30px, 44px)
      width: 87%

  &__search-title
    @include fontSLH(24px, 32px)
    margin-top: 0
    font-weight: 900
    @media (min-width: $tablet)
      font-size: 21px
      font-weight: 500 !important
      width: 61%
      margin-bottom: 24px
    @media (min-width: $desktop-md)
      @include fontSLH(24px, 32px)
      width: 79%
    strong
      font-weight: 500 !important

  &__arrow-bottom
    @include positionAbsolute($left: 47%, $bottom: 16px)
    @media (min-width: $tablet)
      display: none

  &__search-wrapper
    border-collapse: collapse
    background-color: white
    width: 100%
    box-shadow: 0 0 14px 0 rgba(222,231,231,0.55)
    position: relative
    border-bottom-right-radius: 5px
    border-bottom-left-radius: 5px
    z-index: 3
    @media (min-width: $tablet)
      border-top-right-radius: 5px

  &__search-inner-container
    padding: 24px 16px 16px
    min-height: 356px
    @media (min-width: $tablet)
      padding: 24px
      min-height: 278px
    @media (min-width: $desktop-md)
      min-height: 254px
    h3
      margin-bottom: 16px
      line-height: 26px
    &.basic-search-active
      .top-section__basic-search-wrapper
        display: block
      .top-section__ai-search-wrapper
        display: none
    &.ai-search-active
      .top-section__basic-search-wrapper
        display: none
      .top-section__ai-search-wrapper
        display: block

  &__search-block-tabs
    @include displayFlex(center, flex-start)
    @include positionAbsolute(-38px, $left: 0)
    gap: 4px
    width: 100%
    @media (min-width: $tablet)
      width: auto

  &__search-block-tab
    @include displayFlex(center, center)
    @include fontSLH(14px, 24px)
    @include boxSize(100%, 38px)
    color: $default
    border-radius: 5px 5px 0px 0px
    background: #E4E4E3
    cursor: pointer
    border: 1px solid #E4E4E3
    border-bottom: none
    &:hover
      border-color: $green
      border-bottom: none
    @media (min-width: $tablet)
      width: auto
      padding: 0 24px
    svg
      margin-right: 6px
      fill: #AFAFAE
    &.active
      pointer-events: none
      color: $white
      background: $green
      border-color: $green
      svg
        fill: #96DD3B
      &:hover
        background: $green

  &__sales-wrapper
    margin-bottom: 8px
